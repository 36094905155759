import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequest } from '../request';
import { PostSignedUrlResponse, SuccessResponse } from '~/types/source';
import { upload } from './util';
import { type DataObject } from '~/types/data-object';
import { addMessageToPage, createMessage, ExpandedMessage } from '../chat';
import { v4 } from 'uuid';
import { mergeDeepRight } from 'ramda';
import { calculateProgress } from '~/app/(app)/_assistants/(saved-assistant)/[assistantId]/(source)/manage-source/components/SourceActivity';

interface FileUploadVariables {
  chatId: string;
  file: File;
  isNewChat?: boolean;
}

const generateFileId = (file: File) => `${encodeURIComponent(file.name)}-${file.lastModified}`;
const generateFileName = (dataObjects: DataObject[], fileName: string) => {
  const previousDataObjects = dataObjects.filter((dataObject) => dataObject.name.includes(fileName.split('.')[0]));
  if (previousDataObjects.length >= 1) {
    const fileNumbering = `(${previousDataObjects.length})`;
    return `${fileName.split('.')[0]} ${fileNumbering}.${fileName.split('.')[1]}`;
  }
  return fileName;
};

const encodeSpecialUrlChars = (str: string): string => {
  const specialCharsRegex = /[,%/?:@&=+$#]/g;
  return str.replace(specialCharsRegex, (char) => encodeURIComponent(char));
};

export function useSignedURLMutation() {
  const request = useRequest();
  return useMutation({
    mutationKey: ['signed-url'],
    async mutationFn({ chatId, fileName }: { chatId: string; fileName: string }) {
      const response = await request<PostSignedUrlResponse>(`/api/v2/chat/${chatId}/signed-url`, {
        method: 'POST',
        body: JSON.stringify({
          name: encodeSpecialUrlChars(fileName),
        }),
      });
      if (!response || !('data' in response)) {
        throw new Error(
          'Signed URL Mutation error: No response or response does not contain data getSignedUrlMutation',
        );
      }
      if (!response.success) {
        if (response?.data?.detail?.startsWith('Data object already created')) {
          throw new Error('Signed URL Mutation error: Data object already created');
        }
        throw new Error('Signed URL Mutation error: Invalid response from createSignedUrl');
      }
      if (response.success && !response?.data?.upload_signed_url) {
        throw new Error('Signed URL Mutation error: No upload URL returned');
      }
      if (!response.sourceId) {
        throw new Error('Signed URL Mutation error: No sourceId returned');
      }
      return response as SuccessResponse;
    },
  });
}

function useRefreshDataObjectMutation() {
  const request = useRequest();
  return useMutation({
    mutationKey: ['refresh-data-object'],
    async mutationFn(dataObjectId: string) {
      request(`/api/v2/data_objects/${dataObjectId}/refresh`, {
        method: 'POST',
      });
    },
  });
}

/**
 * 1. POST Call create sources API
    1. Logical bucket with different source types
2. POST Call sources signed url using the new source id
3. PUT with the files you want
    1. data_object_id associated with a file
4. POST to /data_objects/{dataObjectId}
5. GET /data_objects/${dataObjectId}
 * @returns
 */
export function useFileUploadMutation() {
  const request = useRequest();
  const client = useQueryClient();
  const { mutateAsync: requestSignedURL } = useSignedURLMutation();
  const { mutateAsync: refreshDataObject } = useRefreshDataObjectMutation();

  return useMutation({
    mutationKey: ['file-upload'],
    async mutationFn({ chatId, file, isNewChat }: FileUploadVariables) {
      let fileName = file.name;

      if (!isNewChat) {
        const { data } = await request<{ data: { data_objects: { data: DataObject[] } } }>(
          `/api/v2/chat/${chatId}/sources`,
          {
            method: 'GET',
          },
        );
        fileName = generateFileName(data.data_objects.data ?? [], file.name);
      }

      const tempMessage = createMessage<ExpandedMessage>({
        id: v4(),
        chat_id: chatId!,
        type: 'human',
        content: '',
        created_at: new Date(),
        event: {
          type: 'file_upload' as const,
          content: fileName,
          progress: 0,
          sync: 0,
        },
      });
      addMessageToPage(tempMessage, client);

      const response: SuccessResponse = await requestSignedURL({ chatId, fileName });
      const {
        sourceId,
        data: { upload_signed_url, data_object_id },
      } = response;

      const fileId = generateFileId(file);

      await upload(upload_signed_url, file, {
        headers: {
          'Content-Type': file.type,
          'x-ms-blob-type': 'BlockBlob',
          'x-ms-meta-source_id': sourceId,
          'x-ms-meta-properties': fileId,
        },
        // Optimistically, we will add a new message
        onProgress(progress) {
          addMessageToPage(
            mergeDeepRight<ExpandedMessage, Partial<ExpandedMessage>>(tempMessage, {
              type: 'human',
              chat_id: chatId as string,
              event: {
                type: 'file_upload',
                content: fileName,
                progress,
              },
            }),
            client,
          );
        },
      });

      await refreshDataObject(data_object_id);

      const { data } = await request<{ data: DataObject }>(`/api/v2/data_objects/${data_object_id}`, { method: 'GET' });

      let progress = 0;
      while (progress < 100) {
        const { data } = await request<{ data: DataObject }>(`/api/v2/data_objects/${data_object_id}`, {
          method: 'GET',
        });

        progress = calculateProgress(data.activities || []).progress;
        if (progress < 100) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        addMessageToPage(
          mergeDeepRight<ExpandedMessage, Partial<ExpandedMessage>>(tempMessage, {
            type: 'human',
            chat_id: chatId as string,
            event: {
              type: 'file_upload',
              content: fileName,
              sync: progress,
            },
          }),
          client,
        );
      }

      client.invalidateQueries({
        queryKey: ['chats', chatId],
      });

      return data;
    },
  });
}
