import { useMutation, useQuery } from '@tanstack/react-query';
import { WebsiteUrlResponse, SuccessWebsiteResponse } from '~/types/source';
import { useRequest } from '../request';

export function useAddWebsiteMutation() {
  const request = useRequest();
  return useMutation({
    mutationKey: ['website'],
    async mutationFn({ assistantId, websiteName }: { assistantId: string; websiteName: string }) {
      const response = await request<WebsiteUrlResponse>(`/api/v2/assistants/${assistantId}/website`, {
        method: 'POST',
        body: JSON.stringify({
          name: websiteName,
          base_url: 'https://apify.com',
        }),
      });
      if (!response || !('data' in response)) {
        throw new Error('Add Website Mutation error: No response or response does not contain data');
      }
      if (!response.success) {
        if (response?.data?.detail?.startsWith('Data object already created')) {
          throw new Error('Add Website Mutation error: Data object already created');
        }
        throw new Error('Add Website Mutation error: Invalid response from creating source');
      }
      if (!response.data.id) {
        throw new Error('Add Website Mutation error: No sourceId returned');
      }
      return response as SuccessWebsiteResponse;
    },
  });
}

export function useUpdateWebsiteMutation() {
  const request = useRequest();
  return useMutation({
    mutationKey: ['website'],
    async mutationFn({
      assistantId,
      sourceId,
      apifyDatasetId,
      websiteName,
      apifyStatus,
    }: {
      assistantId: string;
      sourceId: string;
      apifyDatasetId: string;
      websiteName: string;
      apifyStatus: 'crawling' | 'done';
    }) {
      const response = await request<WebsiteUrlResponse>(`/api/v2/assistants/${assistantId}/website`, {
        method: 'PUT',
        body: JSON.stringify({
          apify_dataset_id: apifyDatasetId,
          source_id: sourceId,
          name: websiteName,
          apify_status: apifyStatus,
        }),
      });
      if (!response || !('data' in response)) {
        throw new Error('Update Website Mutation error: No response or response does not contain data');
      }
      if (!response.success) {
        throw new Error('Update Website Mutation error: Invalid response from updating source');
      }
      if (!response.data.id) {
        throw new Error('Update Website Mutation error: No id returned');
      }
      return response as SuccessWebsiteResponse;
    },
  });
}
