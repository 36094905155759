export { default as AddCircle } from "./icons/add-circle.svg";
export { default as AddCircleHalfDot } from "./icons/add-circle-half-dot.svg";
export { default as AlertInfo } from "./icons/alert-info.svg";
export { default as AlertWarn } from "./icons/alert-warn.svg";
export { default as ArrowDown } from "./icons/arrow-down.svg";
export { default as ArrowRight } from "./icons/arrow-right.svg";
export { default as ArrowUpCircleRight } from "./icons/circle-arrow-up-right.svg";
export { default as AssistantsIcon } from "./icons/assistants.svg";
export { default as AstericksIcon } from "./icons/astericks.svg";
export { default as Chat } from "./icons/chat.svg";
export { default as Check } from "./icons/check.svg";
export { default as CheckMarkBadge } from "./icons/checkmark-badge.svg";
export { default as CircleCheck } from "./icons/circle-check.svg";
export { default as Collection } from "./icons/collection.svg";
export { default as ConnectAppIcon } from "./icons/connect-app.svg";
export { default as CopyIcon } from "./icons/copy.svg";
export { default as EditIcon } from "./icons/edit.svg";
export { default as Folder } from "./icons/folder.svg";
export { default as GlobeIcon } from "./icons/globe.svg";
export { default as GlobeOnIcon } from "./icons/globe-on.svg";
export { default as HelpQuestionMark } from "./icons/help.svg";
export { default as Home } from "./icons/home.svg";
export { default as MagnifyCross } from "./icons/magnify-cross.svg";
export { default as MagnifyingGlassIcon } from "./icons/magnifying-glass.svg";
export { default as MoveLeft } from "./icons/move-left.svg";
export { default as NotificationsIcon } from "./icons/notifications.svg";
export { default as OfficeIcon } from "./icons/office.svg";
export { default as OrganizationIcon } from "./icons/organization.svg";
export { default as PaperClip } from "./icons/paper-clip.svg";
export { default as ProfileIcon } from "./icons/profile.svg";
export { default as PlusSignHalfCircle } from "./icons/plus-sign-circle.svg";
export { default as RecycledBinIcon } from "./icons/recycled-bin.svg";
export { default as Rename } from "./icons/rename.svg";
export { default as SeatsIcon } from "./icons/seats-icon.svg";
export { default as SendIcon } from "./icons/send.svg";
export { default as SettingsIcon } from "./icons/settings.svg";
export { default as SignOutIcon } from "./icons/sign-out.svg";
export { default as SubscriptionIcon } from "./icons/subscription.svg";
export { default as SwitchThumb } from "./icons/switch-thumb.svg";
export { default as ThumbsDownIcon } from "./icons/thumbs-down.svg";
export { default as ThumbsDownFullIcon } from "./icons/thumbs-down-full.svg";
export { default as ThumbsUpIcon } from "./icons/thumbs-up.svg";
export { default as ThumbsUpFullIcon } from "./icons/thumbs-up-full.svg";
export { default as Tick } from "./icons/tick.svg";
export { default as TicketMail } from "./icons/ticket-mail.svg";
export { default as TransferOwner } from "./icons/transfer-owner.svg";
export { default as Trash } from "./icons/trash.svg";
export { default as TriangleDown } from "./icons/triangle-down.svg";
export { default as TripleDot } from "./icons/triple-dot.svg";
export { default as UsersMultiple } from "./icons/users-multiple.svg";
export { default as UserSwitch } from "./icons/user-switch.svg";
export { default as Wandy } from "./icons/wandy.svg";
export { default as SearchIcon } from "./icons/search.svg";
export { default as DownTriangle } from "./icons/down-triangle.svg";
export { default as XIcon } from "./icons/x.svg";
export { default as DocumentIcon } from "./icons/document.svg";
export { default as ReloadFile } from "./icons/reload-file.svg";
export { default as CancelFile } from "./icons/cancel-file.svg";
