import { useMutation } from '@tanstack/react-query';
import { useRequest } from '../request';
import { AdditionalMutationOptions } from '../types';

type RevokeTokenOptions = {
  token: string;
};

export function useRevokeGoogleTokenMutation({
  onMutate,
  onSuccess,
  ...options
}: AdditionalMutationOptions<RevokeTokenOptions, string, ['revoke-google-token']> = {}) {
  const request = useRequest();

  return useMutation({
    ...options,
    mutationKey: ['revoke-google-token'],
    async mutationFn(token: string) {
      return request('/api/v2/sources/google', {
        method: 'DELETE',
        body: JSON.stringify({ token }),
      });
    },
    onSuccess(data, variables, context) {
      return onSuccess?.(data, variables, context);
    },
  });
}
